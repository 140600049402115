@import './variables.scss';
@import './sizing.scss';

.c-form {
  position: relative;
  margin-bottom: $size-page;

  &__input-container {
    overflow: visible;
    &:focus-within {
      .c-form__label {
        color: var(--primary-purple) !important;
      }

      .c-form__input,
      .ng-select-container {
        border-color: var(--primary-purple) !important;
      }
    }
  }

  &__label {
    display: block;
    text-transform: capitalize;
    text-align: left;
    color: var(--gray-400);
    font-size: $font-md;
    line-height: 1.2;
  }

  &__input {
    width: 100%;
    margin-top: $size-sm;
    outline: none;
    display: block;
    border-radius: $common-radius;
    background-color: var(--white);
    border: solid 1px var(--primary-gray);
    font-weight: 600;
    color: var(--black);
    padding: $size-base;

    &.has-error {
      border-color: var(--secondary-red) !important;
    }

    &[type='image'] {
      border-radius: 100%;
      height: 80px;
      width: 80px;
      padding: 0;
      margin: 0;
    }
  }

  &__image {
    position: absolute;
    right: $size-base;
    bottom: $size-page;
    height: 18px;
    cursor: pointer;

    &:hover {
      filter: brightness(0);
    }

    &.has-error {
      bottom: $size-base * 2.25;
    }
  }

  &.has-error {
    input,
    textarea,
    select {
      border-color: var(--secondary-red) !important;
    }
  }
  .answer-option-box {
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-top: $size-xl;
    }
    .item-radio {
      border-radius: $tiny-radius;
      background-color: var(--white);
      border: solid 1px var(--blue-500);
      padding: $size-base $size-hf-page;
      margin-bottom: $size-hf-page;
      display: block;
      text-align: left;
      transition: all 0.2s linear;
      cursor: pointer;
      &:hover {
        background-color: var(--gray-100);
      }

      input[type='radio'] {
        display: none;
      }

      &.checked {
        border: solid 2px var(--primary-purple) label {
          font-weight: 500;
          color: var(--black);
        }
      }

      label {
        cursor: pointer;
        font-size: $font-md;
        color: var(--gray-800);
      }
    }
  }
  .radio {
    margin: $size-page 0 $size-db-page;
    text-align: left;
    label {
      display: inline-block;
      font-size: $font-high;
      font-weight: 500;
      text-align: left;
      color: var(--gray-800);
      height: 100%;
      line-height: 33px;
      margin-right: $size-page;
      padding-top: $size-page;
      cursor: pointer;

      input[type='radio'] {
        margin: 0 $size-base 0 0;
        float: left;
        position: relative;
        height: $size-xl;
        width: $size-lg;
        outline: none;
        cursor: pointer;
      }
      input[type='radio']:before {
        display: block;
        content: ' ';
        position: absolute;
        height: $size-xl;
        width: $size-xl;
        border-radius: 100%;
        border: 1px solid var(--blue-500);
        background: var(--white);
        left: 0;
        bottom: 0;
        cursor: pointer;
      }
      input[type='radio']:checked:after {
        display: block;
        content: ' ';
        position: absolute;
        height: $size-base;
        width: $size-base;
        border-radius: 100%;
        background: var(--primary-purple);
        left: 7px;
        bottom: 7px;
        cursor: pointer;
      }
    }
  }
  .form-item {
    position: relative;
    div {
      position: absolute;
      top: 75px;
      left: 0;
    }
  }

  .ng2-select-custom {
    .ng-select-container {
      margin-top: $size-sm;
      border-radius: $common-radius;
      border-color: var(--primary-gray);
      min-height: $size-base * 3.25 !important;

      .ng-value-container {
        padding-left: 0 !important;
      }
      .ng-input {
        margin-top: $size-hf-page;
        padding: 0 $size-base 0 $size-base !important;
      }
      .ng-arrow {
        border-color: #000 transparent transparent;
      }
      .ng-arrow-wrapper {
        padding: 0;
      }
      input {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
  .ng-select.ng2-select-tags {
    pointer-events: all;

    &--attached {
      &.ng-select-opened {
        .ng-select-container {
          z-index: 997;
        }
      }

      .ng-dropdown-panel {
        z-index: 998;

        &.ng-select-top {
          top: auto !important;
          margin-top: 0;

          .ng-dropdown-panel-items {
            max-height: 240px;
          }
        }
      }
    }

    .ng-select-container {
      height: 100%;
      min-height: 50px;
      padding: 0 $size-hf-page 0 $size-hf-page !important;
      border: solid 1px #e5e5e5;

      .ng-value-container {
        padding-left: 0;
      }

      .ng-input {
        margin-top: $size-hf-page;
        padding: 0 !important;
      }

      input {
        margin: 0;
        padding: 0 !important;
      }
    }

    .ng-placeholder {
      position: static !important;
    }

    .ng-input {
      margin-top: 0 !important;
    }

    .ng-value {
      display: flex;
      align-items: center;
      background: var(--primary-purple) !important;
      padding: 6px $size-xs;
      border-radius: $size-sm !important;
      color: var(--white);
      font-size: 1.1em;

      .ng-value-icon {
        order: 2;
        border: 0 !important;
        font-size: 2em;
        line-height: 0;
        transition: transform 0.1s linear;

        &:hover {
          background: transparent !important;
          transform: scale(1.25);
        }
      }
    }
  }

  select,
  .ng-select-container {
    margin-top: 5px;
    outline: none;
    display: block;
    border-radius: $common-radius;
    background-color: var(--white);
    border: solid 1px var(--blue-500);
    font-weight: 600;
    color: var(--black);
    padding: 13px $size-base $size-sm !important;
    &[type='image'] {
      border-radius: 100%;
      height: 80px;
      width: 80px;
      padding: 0;
      margin: 0;
    }
  }

  &.form-small {
    label {
      font-size: $font-md;
    }
    input {
      padding: 9px 13px$size-sm;
      width: calc(100% - 26px);
      margin-bottom: $size-page;
    }
    button {
      font-size: $font-md;
      line-height: 1.36;
      padding: $size-sm $size-xl;
    }
  }
}

.ng-dropdown-panel {
  pointer-events: all;
  text-align: left;

  &.ng-select-top {
    top: $size-hf-page !important;
    max-height: 242px;
    margin-top: auto;

    .ng-dropdown-panel-items {
      max-height: 100%;
    }
  }
}

ng2-dropdown-menu {
  * {
    font-family: Montserrat !important;
  }
  .ng2-dropdown-menu {
    border: 1px solid var(--secondary-red);
    border-radius: $size-sm !important;
    padding: 0 !important;
  }
}

.c-search-bar {
  border-radius: $tiny-radius;
  background-color: var(--white);
  display: block;
  position: relative;

  i {
    position: absolute;
    padding: $size-base $size-page $size-base $size-page;
    color: #92d0bd;
    background-color: #def5ea;
    font-size: $font-high;
  }

  input {
    padding: $size-base 50px $size-base;
    width: calc(100%);
    border: solid 1px var(--blue-500);
    font-size: $size-base;
    text-align: left;
    color: var(--black);
    font-weight: 500;
    outline: none;

    &::placeholder {
      font-size: $font-md;
      font-weight: normal;
      color: #c4d1de;
    }
  }
}

.observation-form {
  text-align: center;

  .label-info {
    font-size: $font-md;
    color: var(--gray-800);
  }

  .flag-field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5px;

    label {
      min-width: 130px;
      margin-right: 5px;
    }
    input {
      margin: 0;
      width: $size-page;
    }
    select {
      height: 28px;
      padding: 0px;
      font-weight: normal;
      font-size: $font-md;
    }
  }

  textarea {
    border-radius: 2px;
    background-color: var(--white);
    border: solid 1px var(--blue-400);
    resize: none;
    outline: none;
    width: 100%;
    padding: $size-hf-page $size-base;
  }
  textarea::placeholder {
    color: var(gray-80);
  }
  .bt {
    text-transform: capitalize;
    margin-top: $size-base;
    border-radius: $tiny-radius;
    background-color: var(--primary-purple);
    border: none;
    padding: $size-sm $size-lg;
    font-size: $font-md;
    font-weight: 500;
    line-height: 1.07;
    text-align: center;
    color: var(--white);
    outline: none;

    &:disabled {
      opacity: 0.2;
    }
  }
}
