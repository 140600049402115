/* You can add global styles to this file, and also import other style files */
@import 'assets/fonts/font-awesome/css/font-awesome.min.css';
@import 'assets/fonts/font-awesome/css/regular.min.css';
@import 'assets/fonts/font-awesome/css/solid.min.css';
@import 'assets/variables.scss';
@import 'assets/forms.scss';
@import 'assets/grid.scss';
@import 'assets/common.scss';
@import 'assets/cookies.scss';

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-Light.woff2') format('woff2');
  src: url('/assets/fonts/Overpass-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-Regular.eot');
  src: url('/assets/fonts/Overpass-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-SemiBold.eot');
  src: url('/assets/fonts/Overpass-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-Bold.eot');
  src: url('/assets/fonts/Overpass-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('/assets/fonts/Overpass-Black.eot');
  src: url('/assets/fonts/Overpass-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Overpass', sans-serif;
  font-size: 16px;
  font-weight: normal;
}

body {
  display: flex;
  flex-direction: column;

  background: var(--background-blue);
  overflow-y: scroll;

  &.disable-scroll {
    overflow: hidden;
  }
}

app-root {
  min-height: 100vh;
}

.u-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.icon {
  &--success {
    color: var(--primary-purple);
    animation-name: popIn;
    animation-duration: 195ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }
}

@keyframes popIn {
  from {
    transform: translateY($size-hf-page) rotateZ(-45deg);
  }

  to {
    transform: translateY(0px);
  }
}
