@import '../assets/variables.scss';

.cc-window {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);

  max-width: none !important;
  background-color: rgba(30, 27, 44, 0.2) !important;

  font-family: inherit !important;

  &.cc-floating.cc-theme-edgeless {
    a {
      color: var(--primary-purple);
    }

    .cc-message {
      margin: 0;
      padding: 2em;
      padding-bottom: $size-db-page;
      max-width: 34em;
      background: var(--white);
      border-radius: $medium-radius $medium-radius 0 0;
      color: #2c3239;
    }

    .cc-compliance {
      flex-direction: column-reverse;
      width: 100%;
      max-width: 34em;
      flex: 0;
      padding: 2em;
      padding-top: 0;
      background: var(--white);
      border-radius: 0 0 $medium-radius $medium-radius;

      .cc-btn {
        outline: 0;
        border-radius: $common-radius;
        padding: 0.8em 1.6em;
        font-size: 1em;
      }

      .cc-allow {
        border: 0;
        background: var(--primary-purple);
        color: #fff;
        width: 220px;
      }

      .cc-deny {
        margin-top: $size-sm;
        font-weight: 400;
        text-decoration: underline;
        color: var(--primary-purple);
      }
    }
  }
}

.cc-revoke {
  display: none !important;
}
