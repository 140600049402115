:root {
  --primary-purple: #5100ff;

  --primary-gray: #e8e8e8;
  --close-gray: #b3bdc1;

  --primary-green: #90c845;
  --secondary-green: #92d0bd;
  --detail-green: #def5ea;

  --primary-yellow: #f7c662;

  --primary-red: #f24e4e;
  --secondary-red: #ff7c61;

  --primary-blue: #007fff;
  --hover-blue: #8896a8;
  --background-blue: #f4f7fa;
  --blue-100: #dddddd;
  --blue-200: #eeeeee;
  --blue-300: #f3f3f3;
  --blue-400: #dfe2e5;
  --blue-500: #e6eaee;
  --blue-900: #4da1ff;

  --gray-100: #fafbfc;
  --gray-300: #bdbdbd;
  --gray-400: #7f7f7f;
  --gray-500: #707577;
  --gray-800: #4c4c4c;
  --gray-900: #2c3239;

  --white: #ffffff;
  --black: #000000;
}
