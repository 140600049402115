$size-base: 1rem;
$size-xs: $size-base * 0.25;
$size-sm: $size-base * 0.75;
$size-md: $size-base * 1;
$size-lg: $size-base * 1.5;
$size-xl: $size-base * 2;
$size-page: $size-base * 1.25;
$size-hf-page: calc($size-page / 2);
$size-db-page: $size-page * 2;

$common-radius: 12px;
$medium-radius: 6px;
$tiny-radius: 3px;

$font-xxl: 36px;
$font-xl: 28px;
$font-lg: 22px;
$font-high: 18px;
$font-md2: 15px;
$font-md: 14px;
$font-sm: 12px;

$break-xs: 360px;
$break-sm: 640px;
$break-md: 992px;
$break-lg: 1200px;
$break-xl: 1440px;

$max-xs: 480px;
$max-sm: 640px;
$max-md: 768px;
$max-md2: 1170px;
$max-lg: 1366px;
$max-xl: 1440px;
